import { useEffect, useState } from "react";

export const useClientData = () => {
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [data, setData] = useState(null);
    const [ip, setIp] = useState(null);

    useEffect(() => {
        const controller = new AbortController();

        const fetchData = async () => {
            setIsPending(true);

            try {
                const res = await fetch("https://geolocation-db.com/json/", {
                    signal: controller.signal,
                    mode: "cors",
                });
                if (!res.ok) {
                    throw new Error(res.statusText);
                }
                const data = await res.json();
                setIp(data.IPv4);
                setIsPending(false);
                setData(data);
                setError(null);
            } catch (err) {
                if (err.name === "AbortError") {
                    console.log("the fetch was aborted");
                } else {
                    setIsPending(false);
                    setError("Could not fetch the data");
                }
            }
        };

        fetchData();

        // handle unexpected page navigation while request in-flight.
        return () => {
            controller.abort();
        };
    }, []);

    return { isPending, error, data, ip };
};

// Example Only
const sampleAPIResponse = {
    country_code: "GB",
    country_name: "United Kingdom",
    city: "Chipping Norton",
    postal: "OX7",
    latitude: 51.9411,
    longitude: -1.5453000000000001,
    IPv4: "86.172.7.180",
    state: "Oxfordshire",
};
