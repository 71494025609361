import { useEffect, useState } from "react";

// firebase imports
import { db } from "../firebase/config";
import { doc, onSnapshot } from "firebase/firestore";

//! intended to be used with a popup or details view for an item ___
//! ___ and to use the useParams() hook to retrieve query param.

export const useDocument = (collection, id) => {
    const [document, setDocument] = useState(null);
    const [error, setError] = useState(null);

    // realtime data for document
    useEffect(() => {
        const unsub = onSnapshot(
            doc(db, collection, id),
            (snapshot) => {
                if (snapshot.data()) {
                    setDocument({ ...snapshot.data(), id: snapshot.id });
                    setError(null);
                } else {
                    setError("document does not exist");
                }
            },
            (err) => {
                console.log(err);
                setError("failed to get document");
            }
        );
        return () => unsub();
    }, [collection, id]);
    // run the code whenever the collection or id changes.

    return { document, error };
};

// ! Use this hook to retrieve a single document from Firebase.
