export default function Home() {
    return (
        <>
            {/* add 'dark' classname to the root div to enable the dark classes. */}
            <div className="page-home">
                <div className="dark:bg-slate-900">
                    <div className="dark:text-white">About page</div>
                </div>
            </div>
        </>
    );
}
