import Button from "../components/Button";
// import { TextField } from "../components/Fields";
import LayoutCenter from "../components/LayoutCenter";
//import { useCollection } from "../Hooks/useCollection";
import { useFirestore } from "../hooks/useFirestore";
import React, { useEffect } from "react";
import Logo from "../components/Logo";
import SubmitEmail from "../components/SubmitEmail";
import SubmitMessage from "../components/SubmitMessage";
import { useLocalStorage } from "../hooks/useLocalStorage";
import SuccessIcon from "../components/SuccessIcon";
import { useDocument } from "../hooks/useDocument";

// VIEWS
const email = ({ ...props }) => (
    <div
        className="my-4 mx-auto text-center text-slate-700 text-md md:max-w-3xl underline underline-offset-4 decoration-slate-400 decoration-2 hover:cursor-pointer"
        {...props}
    >
        Join the waiting list
    </div>
);
const message = ({ ...props }) => (
    <div
        className="my-4 mx-auto text-center text-slate-700 text-md md:max-w-3xl underline underline-offset-4 decoration-slate-400 decoration-2 hover:cursor-pointer"
        {...props}
    >
        Have a suggestion? Let us know here
    </div>
);
const ViewList = { email, message };

function SelectedView(props) {
    const { name } = props;
    const ViewName = name ? name : "email";
    const SelectedView = ViewList[ViewName];

    return <SelectedView {...props}></SelectedView>;
}

export default function Home() {
    const { document: media } = useDocument("media", "logo-main");
    const [logoLoaded, setLogoLoaded] = React.useState(false);

    const [currentView, setCurrentView] = React.useState("message");
    const [submitted, setSubmitted] = useLocalStorage("r-submitted", false);
    const [submittedMessage, setSubmittedMessage] = useLocalStorage(
        "r-submittedmessage",
        false
    );

    // track state for pages that was submitted.
    const handleFormSubmit = (data) => {
        if (data === "email") setSubmitted(true);
        if (data === "message") setSubmittedMessage(true);
    };

    const handleSetCurrentView = () => {
        if (currentView === "email") setCurrentView("message");
        if (currentView === "message") setCurrentView("email");
    };

    return (
        <>
            <LayoutCenter>
                {/* add 'dark' classname to the root div to enable the dark classes. */}
                <div className="page-home ">
                    <div className="-mx-4  md:flex bg-white py-10 px-4 shadow-2xl shadow-gray-900/10 sm:mx-0 sm:flex-none sm:rounded-md sm:p-8">
                        <div className="min md:pr-8 md:w-1/2  md:border-r border-gray-100">
                            <div
                                className={`logo-container  img-box ${
                                    logoLoaded ? "fade-in" : ""
                                }`}
                            >
                                {/* <Logo></Logo> */}
                                <img
                                    className="logo"
                                    src={media?.imageURL}
                                    alt="logo-image"
                                    width={300}
                                    onLoad={(e) => setLogoLoaded(true)}
                                />
                            </div>

                            <div className="my-8 text-2xl font-bold tracking-tighter py-2 text-slate-900">
                                The last platform you'll ever need for
                                operational resilience management
                            </div>
                            {/* The only platform you'll need to manage your firm's operational resilience */}
                            {/* <div className="my-8 text-2xl font-bold tracking-tighter py-2 text-slate-900">
                                A purpose-built platform that simplifies
                                Operational Resilience management
                                // Solution
                                //
                                Moves with you as everybody learns what resilience means to them.
                                consistently

                            </div> */}
                            <div className="text-md sm:text-lg sm:tracking-tighter text-slate-700">
                                {" "}
                                Meeting regulatory and organisational
                                expectations require firms to continuosly
                                evaluate complex data sets. <br />
                                <br />
                                We are developing a purpose-built platform that
                                simplifies data analysis and visualisation,
                                enabling firms to gain real-time insights and
                                proactively manage vulnerabilities.
                                <br />
                                {/* <br /> If this sounds appealing, we want to be a
                                resource for you. */}
                            </div>
                        </div>
                        <div className="flex justify-between flex-col md:pl-8 md:w-1/2 pt-0">
                            <div></div>

                            {currentView !== "email" && (
                                <>
                                    {!submitted && (
                                        <SubmitEmail
                                            onSubmit={handleFormSubmit}
                                        ></SubmitEmail>
                                    )}
                                    {submitted && (
                                        <>
                                            <div className="flex flex-col justify-center mt-8 mb-44">
                                                <div className="text-center">
                                                    <SuccessIcon></SuccessIcon>
                                                </div>
                                                <h2 className="mt-4 text-lg font-semibold text-gray-900 text-center ">
                                                    Thank you!
                                                </h2>
                                                <p className="mt-4 text-md sm:text-lg sm:tracking-tighter text-slate-700 text-center">
                                                    We're working hard to make
                                                    the platform the best it can
                                                    be. <br />
                                                    <br />
                                                </p>
                                                <p className="mt-2 text-md sm:text-lg sm:tracking-tighter text-slate-700 text-center">
                                                    We'll let you know when
                                                    we're ready!
                                                </p>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            {currentView !== "message" && (
                                <>
                                    {!submittedMessage && (
                                        <SubmitMessage
                                            onSubmit={handleFormSubmit}
                                        ></SubmitMessage>
                                    )}
                                    {submittedMessage && (
                                        <>
                                            <div className="flex flex-col justify-center mt-8 mb-44">
                                                <div className="text-center">
                                                    <SuccessIcon></SuccessIcon>
                                                </div>
                                                <h2 className="mt-4 text-lg font-semibold text-gray-900 text-center ">
                                                    Thank you!
                                                </h2>
                                                <p className="mt-4 text-md sm:text-lg sm:tracking-tighter text-slate-700 text-center">
                                                    We're working hard to make
                                                    the platform the best it can
                                                    be. <br />
                                                    <br />
                                                </p>
                                                <p className="mt-2 text-md sm:text-lg sm:tracking-tighter text-slate-700 text-center">
                                                    We'll let you know when
                                                    we're ready!
                                                </p>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            <SelectedView
                                name={currentView}
                                onClick={() => handleSetCurrentView()}
                            ></SelectedView>
                            <div className="mx-auto text-center max-w-md text-gray-400 text-md md:max-w-3xl mt-8 hover:animate-pulse">
                                Simple. Transparent. Effective.
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutCenter>
        </>
    );
}
