import { initializeApp } from "firebase/app";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyCf9TJFT1_V-Rk23cpBf9wkfS_pIZfEy_g",
    authDomain: "newco-signup.firebaseapp.com",
    projectId: "newco-signup",
    storageBucket: "newco-signup.appspot.com",
    messagingSenderId: "208896631697",
    appId: "1:208896631697:web:5ece21ebe6fb0a6e1b3030",
};

// init firebase
const app = initializeApp(firebaseConfig);

// init firebase auth
const auth = getAuth(app);

// init firestore
const db = getFirestore(app);

// init file storage
const storage = getStorage(app);

//timstamp
const timestamp = serverTimestamp();

// error messages
const parseError = (errCode) => {
    switch (errCode) {
        case "auth/user-not-found":
            return "Unable to find this account";
        case "auth/wrong-password":
            return "Invalid username or password";
        case "auth/invalid-email":
            return "Invalid email address";
        default:
            return "An error occurred";
    }
    //todo ERROR_CODES:  https://firebase.google.com/docs/auth/admin/errors
};

export { auth, db, storage, timestamp, parseError };
