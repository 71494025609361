import { BrowserRouter, Route, Switch } from "react-router-dom";
import React from "react";
import { auth, db, timestamp } from "./firebase/config";
import { signInAnonymously } from "firebase/auth";
import { useClientData } from "./hooks/useClientData";
import { useFirestore } from "./hooks/useFirestore";

import {
    collection,
    onSnapshot,
    query,
    orderBy,
    where,
    limit,
    getDocs,
    serverTimestamp,
} from "firebase/firestore";

import Home from "./pages/home";
import About from "./pages/about";

import "./App.css";
import { useCollection } from "./hooks/useCollection";

function App() {
    //* Get current client ip address
    const { data: clientData } = useClientData();
    const { addDocument, updateDocument, response } = useFirestore("visitor");

    //*
    //* Used for IP checks of all visitors.
    //*
    React.useEffect(() => {
        const fetchData = async (ip) => {
            let visitorRef = collection(db, "visitor");
            const visitorQuery = query(visitorRef, where("ip", "==", ip));
            const visitorSnapshot = await getDocs(visitorQuery);

            // this is a clean approach to ensure the data is saved as it should
            let results = [];
            visitorSnapshot.forEach((doc) => {
                results.push({ ...doc.data(), id: doc.id });
            });

            // valid ip found - update visit count
            if (results && results.length > 0) {
                updateDocument(results[0].id, {
                    visits: results[0].visits + 1,
                    updatedAt: timestamp,
                });
            } else {
                // no ip found - create new record
                addDocument({
                    clientData,
                    visits: 1,
                    ip,
                    updatedAt: timestamp,
                });
            }
        };

        // update data if there's a valid record
        if (clientData) {
            fetchData(clientData.IPv4);
        }
    }, [clientData]);

    React.useEffect(() => {
        // check firestore response
        // console.dir(response);
    }, [response]);

    //*
    //* Handle anonymous access for the application
    //*
    React.useEffect(() => {
        signInAnonymously(auth)
            .then(() => {
                // Signed in..
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                console.dir(errorCode);
                console.dir(errorMessage);
                // ...
            });
    }, []);

    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/about">
                        <About />
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
