import React, { useEffect } from "react";
import { useFirestore } from "../hooks/useFirestore";
import Button from "./Button";

// INPUT CLASSES
const formClasses =
    "block w-full appearance-none rounded border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-slate-900 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm";

// const formClasses =
// "block w-full appearance-none rounded border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm";

export default function SubmitMessage({ onSubmit }) {
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [message, setMessage] = React.useState("");

    const { addDocument, response } = useFirestore("subscription");

    const handleSubmit = (e) => {
        e.preventDefault();
        addDocument({ name, email, message });
    };

    useEffect(() => {
        if (response.success) {
            // emit
            onSubmit("message");
        }
    }, [response]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="my-6 text-3xl font-bold tracking-tight py-2 text-gray-900 text-center">
                    Curious?
                </div>
                <p className="mx-auto text-center max-w-md font-semibold text-slate-900 text-lg md:max-w-3xl">
                    {/* Get notified when we launch (late 2023) */}
                    Sign-up and be the first to know when we launch late 2023
                </p>
                <div className="my-8">
                    <div className="">
                        <div className="pb-6">
                            <input
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                name="name"
                                placeholder="Name (optional)"
                                autoComplete="name"
                                className={formClasses}
                            />
                        </div>
                        <div className="pb-6">
                            <input
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                name="email"
                                placeholder="Email"
                                autoComplete="email"
                                required
                                className={formClasses}
                            />
                        </div>
                        <div className="pb-6">
                            <textarea
                                id="message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                rows="4"
                                name="message"
                                placeholder="Suggestion"
                                required
                                className={formClasses}
                            />
                        </div>
                    </div>
                    <div>
                        <Button
                            type="submit"
                            variant="solid"
                            color="slate"
                            className="w-full"
                        >
                            <span>
                                Submit
                                <span aria-hidden="true">&rarr;</span>
                            </span>
                        </Button>
                        <div className="mt-4 mx-auto text-center text-gray-400 text-md md:max-w-3xl italic">
                            We promise not to share your email.
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
