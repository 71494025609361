import React, { useReducer, useEffect, useState } from "react";
import { useAuthContext } from "./useAuthContext";

// firebase imports
import { db, timestamp } from "../firebase/config";
import {
    collection,
    doc,
    addDoc,
    deleteDoc,
    updateDoc,
} from "firebase/firestore";

let initialState = {
    document: null,
    isPending: false,
    error: null,
    success: null,
};

const firestoreReducer = (state, action) => {
    switch (action.type) {
        case "IS_PENDING":
            return {
                isPending: true,
                document: null,
                success: false,
                error: null,
            };
        case "ADDED_DOCUMENT":
            return {
                isPending: false,
                document: action.payload,
                success: true,
                error: null,
            };
        case "DELETED_DOCUMENT":
            return {
                isPending: false,
                document: null,
                success: true,
                error: null,
            };
        case "UPDATED_DOCUMENT":
            return {
                isPending: false,
                document: action.payload,
                success: true,
                error: null,
            };
        case "ERROR":
            return {
                isPending: false,
                document: null,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const useFirestore = (col) => {
    const [response, dispatch] = useReducer(firestoreReducer, initialState);
    const [isCancelled, setIsCancelled] = useState(false);
    const isCancelledRef = React.useRef(false);
    const { user } = useAuthContext();

    // save the name of collection
    const collectionName = col;

    // only dispatch is not cancelled
    const dispatchIfNotCancelled = (action) => {
        //console.dir(isCancelled);
        // if (!isCancelled) {
        //     console.dir(action);
        //     dispatch(action);
        // }

        if (!isCancelledRef.current) {
            dispatch(action);
        }
    };
    // add a document
    const addDocument = async (doc) => {
        dispatch({ type: "IS_PENDING" });
        try {
            const docData = {
                ...doc,
                createdAt: timestamp,
                uid: user.uid,
                uname: user.displayName,
                uemail: user.email,
            };

            const addedDocument = await addDoc(
                collection(db, collectionName),
                docData
            );

            dispatchIfNotCancelled({
                type: "ADDED_DOCUMENT",
                payload: addedDocument,
            });
        } catch (err) {
            dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
        }
    };
    // delete a document
    const deleteDocument = async (id) => {
        dispatch({ type: "IS_PENDING" });

        try {
            await deleteDoc(doc(db, collectionName, id));
            dispatchIfNotCancelled({ type: "DELETED_DOCUMENT" });
        } catch (err) {
            dispatchIfNotCancelled({
                type: "ERROR",
                payload: "could not delete",
            });
        }
    };
    // update a document
    const updateDocument = async (id, updates) => {
        dispatch({ type: "IS_PENDING" });
        try {
            const updatedDocument = await updateDoc(
                doc(db, collectionName, id),
                updates
            );

            dispatchIfNotCancelled({
                type: "UPDATED_DOCUMENT",
                payload: updatedDocument,
            });
            return updatedDocument;
        } catch (err) {
            dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
            return null;
        }
    };

    useEffect(() => {
        isCancelledRef.current = false;
        return () => {
            //setIsCancelled(true); // OLD WAY
            isCancelledRef.current = true;
        };
    }, []);

    return {
        addDocument,
        updateDocument,
        deleteDocument,
        response,
        isCancelled: isCancelledRef.current,
    };
};

// ! ##########################################
// ! ############## REACT 18 ##################
// ! ##########################################
//* Strict mode and useEffect() in React 18
/*
    With the double load of components introduced in React 18, using a useEffect() inside a custom hook does not 
    produce expected behaviour with the cleanup function setting state. Instead, make use of a useRef(), and 
    force a reset of the value in the useEffect() to it's original state when the useRef gets initiliazed. Then
    on the unload, perform any additional changfes on the ref.

*/

// ! Use this hook to perform Create, Update and Delete operations on documents in Firebase.

//* NOTES
/*

Difference between addDoc and setDoc:
    - addDoc - fb sets the id.
    - setDoc - user set a custom id.

Possibly use set and merge flag to prevent overwriting any fields but appending them.   
    const cityRef = doc(db, 'cities', 'BJ');
    setDoc(cityRef, { capital: true }, { merge: true });

Use "dot notation" to reference nested fields within the document when you call update()
    await updateDoc(frankDocRef, {
        "age": 13,
        "favorites.color": "Red"
    });

*/
