const variantStyles = {
    blue: "",
    red: "",
    green: "text-emerald-500",
    slate: "",
};

export default function SuccessIcon({ color = "green" }) {
    const classList = `inline icon icon-tabler icon-tabler-circle-check ${variantStyles[color]}`;

    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className={classList}
                width={64}
                height={64}
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx={12} cy={12} r={9}></circle>
                <path d="M9 12l2 2l4 -4"></path>
            </svg>
        </>
    );
}
